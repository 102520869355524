import type { User } from '@prisma/client';

import { Form, Link } from '@remix-run/react';
import {
	IconAccount,
	IconList,
	IconLogout,
} from '~/components/atoms/Icons/index.tsx';

import React, { useRef } from 'react';

export const Header = ({
	user,
	nologin,
}: {
	user: Omit<
		User,
		| 'createdAt'
		| 'updatedAt'
		| 'email'
		| 'budget'
		| 'passwordHash'
		| 'validationToken'
	> | null;
	nologin?: boolean;
}) => {
	const header: any = useRef(null);

	// Menu button to toggle the main nav
	const toggleHeader = (e: React.MouseEvent) => {
		header.current && header.current.classList.toggle('active');
		e.currentTarget.setAttribute(
			'aria-expanded',
			header.current.classList.contains('active'),
		);
	};

	// Clicking outside the main nav, or on a button/a in the nav, closes the nav
	const closeHeader = (e: any) => {
		if (
			['a', 'button'].indexOf(e.target.tagName.toLowerCase()) !== -1 ||
			e.pageX < e.target.getBoundingClientRect().left
		) {
			header.current && header.current.classList.remove('active');
		}
	};

	return (
		<header className="header" ref={header}>
			<a className="skip-link" href="#main">
				Skip to content
			</a>
			<div className="container">
				<div className="title-section">
					<Link to="/" rel="home" className="home-link">
						GList
					</Link>
					<nav
						id="main-main"
						className="main-nav"
						onClick={(e) => closeHeader(e)}
						onKeyDown={(e) => {
							if (e.key === 'Escape') {
								closeHeader(e);
							}
						}}
					>
						<ul className="main-nav--list">
							<li>
								<Link to="/about" prefetch="intent">
									About
								</Link>
							</li>
							<li>
								<Link to="/features" prefetch="intent">
									Features
								</Link>
							</li>
							{user ? null : (
								<li>
									<Link to="/pricing" prefetch="intent">
										Pricing
									</Link>
								</li>
							)}
							<li>
								<Link to="/changelog" prefetch="intent">
									Changelog
								</Link>
							</li>
							<li>
								<Link to="/contact" prefetch="intent">
									Contact
								</Link>
							</li>
						</ul>
						{user ? (
							<ul className="main-nav--list sub-main-nav">
								<li>
									<Link to="/groups" prefetch="intent">
										Your groups
									</Link>
								</li>
								<li>
									<Link to="/account" prefetch="intent">
										Your account
									</Link>
								</li>
								<li>
									<Form
										action="/account/logout"
										method="post"
									>
										<button
											className="button"
											aria-label="Logout of your account"
										>
											Logout
										</button>
									</Form>
								</li>
							</ul>
						) : !nologin ? (
							<ul className="main-nav--list sub-main-nav">
								<li>
									<Link
										to="/account/login"
										className="button button--ghost"
										prefetch="intent"
									>
										Login
									</Link>
								</li>
							</ul>
						) : null}
					</nav>
				</div>
				<div className="sub-section">
					{user ? (
						<div className="user-info">
							<span>{`Hi ${user.name}`}</span>
							<Link
								to="/groups"
								className="button ghost"
								title="Your groups"
								prefetch="intent"
							>
								<IconList />
							</Link>
							<Link
								to="/account"
								className="button ghost"
								title="Manage your account"
								prefetch="intent"
							>
								<IconAccount />
							</Link>
							<Link
								to="/account/logout"
								className="button"
								aria-label="Logout of your account"
								prefetch="intent"
							>
								<IconLogout />
							</Link>
						</div>
					) : !nologin ? (
						<Link
							to="/account/login"
							className="button button--ghost"
							prefetch="intent"
						>
							Login
						</Link>
					) : null}
					<button
						className="button menu-toggle"
						aria-label="Toggle menu"
						onClick={(e) => toggleHeader(e)}
						aria-controls="main-nav"
						aria-expanded="false"
					>
						<svg
							className="ham"
							viewBox="0 0 100 100"
							width="40"
							fill="currentColor"
						>
							<path
								className="line top"
								d="m 70,33 h -40 c 0,0 -8.5,-0.149796 -8.5,8.5 0,8.649796 8.5,8.5 8.5,8.5 h 20 v -20"
							></path>
							<path
								className="line middle"
								d="m 70,50 h -40"
							></path>
							<path
								className="line bottom"
								d="m 30,67 h 40 c 0,0 8.5,0.149796 8.5,-8.5 0,-8.649796 -8.5,-8.5 -8.5,-8.5 h -20 v 20"
							></path>
						</svg>
					</button>
				</div>
			</div>
		</header>
	);
};
